var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _vm._m(0),
      _c(
        "v-tabs",
        [
          _c(
            "v-tab",
            {
              on: {
                click: function ($event) {
                  return _vm.loadReports()
                },
              },
            },
            [_vm._v("Reports")]
          ),
          _c(
            "v-tab",
            {
              on: {
                click: function ($event) {
                  return _vm.loadFieldLists()
                },
              },
            },
            [_vm._v("Field Lists")]
          ),
          _c(
            "v-tab",
            {
              on: {
                click: function ($event) {
                  return _vm.loadSiteGroups()
                },
              },
            },
            [_vm._v("Site Groups")]
          ),
          _c(
            "v-tab-item",
            [_c("rpt-list", { attrs: { tick: _vm.rptTick } })],
            1
          ),
          _c("v-tab-item", [_c("rpt-field-lists")], 1),
          _c(
            "v-tab-item",
            [_c("create-group", { attrs: { tick: _vm.sgTick } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h1", [_vm._v("Report Management")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }