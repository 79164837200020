<template>
  <v-card class="card-shadow border-radius-xl" height="325px">
    <div style="overflow-x: hidden">
      <div class="pb-0 px-4 pt-4">
        <div class="d-flex align-items-center">
          <h6 class="text-h6 text-typo mb-4 font-weight-bold">
            Select Month and Year
          </h6>
        </div>
      </div>
      <div>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <div>
              <v-select
                :items="months"
                label="Select Month"
                v-model="selectedMonth"
                required
              ></v-select>
              <v-select
                :items="years"
                label="Select Year"
                v-model="selectedYear"
                required
              ></v-select>
              <v-btn
                :loading="loading"
                block
                class="font-weight-bold text-xs btn-success bg-gradient-success"
                @click="
                  $emit('run', {
                    month: getMonthFromString(selectedMonth),
                    year: selectedYear,
                    type: 'monthly'
                  })
                "
              >
                Run Report
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    loading: Boolean,
  },
  data: function () {
    return {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      years: [],
      selectedYear: null,
      selectedMonth: null,
    };
  },
  created: function () {
    console.log("loading");
    this.getYears();
  },
  watch: {
    loading() {
      console.log(`loading changed; loading=${this.loading}`);
    },
  },
  methods: {
    getYears() {
      const currentYear = new Date().getFullYear();
      const pastYears = [currentYear];
      for (let i = 1; i <= 5; i++) {
          pastYears.push(currentYear - i);
      }
      this.years = pastYears;
    },
    getMonthFromString(mon) {
      return new Date(Date.parse(mon + " 1, 2012")).getMonth() + 1;
    },
  },
};
</script>

<style>
</style>