var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list" },
    [
      _c("p", [_vm._v("Select Fields")]),
      _vm._l(_vm.data, function (item, index) {
        return _c(
          "div",
          {
            key: item.id,
            class: _vm.handleDropClasses(item),
            attrs: { value: item.id, draggable: "" },
            on: {
              dragstart: function ($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.pickupElem($event, item, index)
              },
              dragover: function ($event) {
                $event.preventDefault()
                return _vm.showDropPlace($event, item, index)
              },
              dragenter: function ($event) {
                $event.preventDefault()
              },
              drop: function ($event) {
                return _vm.moveElem($event, item, index)
              },
              dragend: function ($event) {
                $event.preventDefault()
                return _vm.dragEndClear()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "list__elem",
                class: {
                  "list__elem--is-dragged":
                    _vm.dragedElem && item.id === _vm.dragedElem.id,
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("v-checkbox", {
                      attrs: { label: "" + item.name },
                      on: {
                        change: function ($event) {
                          return _vm.valueChanged($event, item.id)
                        },
                      },
                      model: {
                        value: item.checked,
                        callback: function ($$v) {
                          _vm.$set(item, "checked", $$v)
                        },
                        expression: "item.checked",
                      },
                    }),
                    _vm._v(" "),
                    _c("button", { staticClass: "btn-drag" }),
                  ],
                  1
                ),
              ]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }