<template>
  <v-card class="card-shadow border-radius-xl" height="325px">
    <div style="overflow-x: hidden">
      <div class="pb-0 px-4 pt-4">
        <div class="d-flex align-items-center">
          <h6 class="text-h6 text-typo mb-4 font-weight-bold">
            Select Quarter and Year
          </h6>
        </div>
      </div>
      <div>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <div>
              <v-select
                :items="quarters"
                label="Select Quarter"
                v-model="selectedQuarter"
                required
              ></v-select>
              <v-select
                :items="years"
                label="Select Year"
                v-model="selectedYear"
                required
              ></v-select>
              <v-btn
                block
                class="font-weight-bold text-xs btn-success bg-gradient-success"
                @click="
                  $emit('run', {
                    month: getMonthFromQtrString(selectedQuarter),
                    year: selectedYear,
                    type: 'quarterly',
                  })
                "
              >
                Run Report
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {

  },
  data: function () {
    return {
      selectedYear: null,
      selectedQuarter: null,
      quarters: ["Q1", "Q2", "Q3", "Q4"],
      years: [2024, 2023, 2022, 2021, 2020],
    };
  },
  created: function () {},
  methods: {
    getMonthFromQtrString(q) {
            if(q == "Q1") {
                return 1;
            } else if(q == "Q2") {
                return 4;
            } else if(q == "Q3") {
                return 7;
            } else {
                return 10;
            }
        },
  },
};
</script>

<style>
</style>