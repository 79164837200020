<template>
  <div>
    <component :is="current" :loading="loading" @run="run"></component>
    <v-row class="mx-2 my-10">
      <Grid ref="grid" :data-items="items" :columns="columns">
        <!-- <grid-toolbar>
          <v-btn
            v-show="this.reportGenerated"
            @click="exportExcel"
            id="exp_many"
            class="font-weight-bold text-xs btn-default bg-gradient-default"
          >
            Export Report to Excel
          </v-btn>
        </grid-toolbar> -->
      </Grid>
    </v-row>
  </div>
</template>

<script>
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import Service from "@/services/Service.js";
import MonthInput from "./MonthInput.vue";
import QuarterInput from "./QuarterInput.vue";
export default {
  components: {
    monthly: MonthInput,
    quarterly: QuarterInput,
    Grid: Grid,
    "grid-toolbar": GridToolbar,
  },
  props: {
    rpt: Object,
  },
  computed: {
    columns: function () {
      // let baseColumns = [{ field: "name", width: "100px" }];
      let baseColumns = [];
      // add in additional columns

      if (this.rpt.rpt_field_list.rpt_field_groups.length > 0) {
        // do a quick sort by order property here...
        this.rpt.rpt_field_list.rpt_field_groups.sort(this.dynamicSort("order"));
        this.rpt.rpt_field_list.rpt_field_groups.forEach((element) => {
          let exists = baseColumns.find((c) => c.field == element.rpt_field);
          if (!exists)
            baseColumns.push({ field: element.rpt_field, width: "100px", title: element.rpt_field_column_text });
        });
      }
      console.log(baseColumns)
      return baseColumns;
    },
  },
  created: function () {
    console.log(this.rpt);
    this.setData();
  },
  data: function () {
    return {
      reportGenerated: false,
      loading: false,
      selectedInternalReport: null,
      items: [],
      current: "monthly",
    };
  },
  methods: {
    dynamicSort(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
            * and you may want to customize it to your needs
            */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    },
    getMonthlyData(month, year) {
      // determine what type of input is needed
      NProgress.start();
      this.loading = true;
      Service.monthlyReport(this.rpt.rpt_site_group.id, month, year)
        .then((response) => {
          console.log(response);
          this.items = response.data;
          NProgress.done();
          this.reportGenerated = true;
          this.loading = false;
          this.exportExcel();
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          NProgress.done();
        });
    },
    getQuarterlyData(month, year) {
      // determine what type of input is needed
      NProgress.start();
      this.loading = true;
      Service.quarterlyReport(this.rpt.rpt_site_group.id, month, year)
        .then((response) => {
          console.log(response);
          this.items = response.data;
          NProgress.done();
          this.reportGenerated = true;
          this.loading = false;
          this.exportExcel();
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          NProgress.done();
        });
    },
    setData() {
      this.reportGenerated = false;
      this.current = this.rpt.rpt_field_list.rpt_internal_report_name;
    },
    run(e) {
      console.log(`run: month: ${e.month}, year: ${e.year}, type: ${e.type}`);
      switch (e.type) {
        case "monthly":
          return this.getMonthlyData(e.month, e.year);
          break;
        case "quarterly":
          return this.getQuarterlyData(e.month, e.year);
          break;
        default:
          return null;
      }
    },
    exportExcel() {
      saveExcel({
        // data: this.dataToExport(),
        data: this.items,
        fileName: `${this.rpt.name}.xlsx`,
        columns: this.columns,
        allPages: true,
      });
    },
  },
  watch: {
    rpt() {
      console.log(`report changed; id=${this.rpt.id},name=${this.rpt.name}`);
      setData();
    },
  },
};
</script>

<style>
</style>