<template>
  <div>
    <v-row align="start">
      <v-col cols="6">
        <div class="border-radius-md mt-2 ml-4 mr-4">
          <v-text-field label="Group Name" v-model="newGroupName">
          </v-text-field>
        </div>
      </v-col>
      <v-col cols="6">
        <div style="text-align: left">
          <v-btn
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-success bg-gradient-success"
            @click="createGroup"
            >Create Group</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Grid
          ref="grid"
          :style="{ height: '600px' }"
          :data-items="result"
          :sortable="true"
          :sort="sort"
          :filterable="true"
          :filter="filter"
          :pageable="pageable"
          :take="take"
          :skip="skip"
          :page-size="pageSize"
          :total="total"
          :column-props="columnProps"
          @sortchange="sortChangeHandler"
          @filterchange="filterChangeHandler"
          @pagechange="pageChangeHandler"
          :detail="cellTemplate"
          :columns="columns"
          @expandchange="expandChange"
          :expand-field="expandedField"
        >
          <template v-slot:myTemplate="{ props }">
            <custom :data-item="props.dataItem" />
          </template>
          <template v-slot:previewTemplate="{ props }">
            <previewcell :data-item="props.dataItem" @click="previewRptList" />
          </template>
          <template v-slot:genTemplate="{ props }">
            <td>
              <v-btn
                class="mx-2"
                icon
                color="green"
                @click="generateData(props.dataItem)"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </td>
          </template>
          <template v-slot:delTemplate="{ props }">
            <deletecell :data-item="props.dataItem" @remove="deleteSiteGroup" />
          </template>
        </Grid>
      </v-col>
    </v-row>
    <k-dialog
      v-if="showDialog"
      @close="showDialog = false"
      width="950px"
      overlay="false"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="my-data-table-container">
              <v-data-table
                :headers="headers"
                :items="previewData"
                v-if="previewData.length > 0"
              ></v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <dialog-actions-bar>
        <kbutton @click="cancel"> Close </kbutton>
      </dialog-actions-bar>
    </k-dialog>
    <k-dialog
      v-if="dialogVisible"
      width="850px"
      title="Copy the code below"
      @close="dialogVisible = false"
    >
      <textarea
        class="k-textarea"
        ref="codeTextarea"
        :value="code"
        rows="5"
        readonly
        style="width: 100%; box-sizing: border-box"
      ></textarea>
    </k-dialog>
    <k-dialog 
      v-if="dialogVisible2" 
      :width="350"
      :height="150"
      :title="'Edit Group Name'"
      @close="dialogVisible2 = false"
      >
      <input v-model="editedText" type="text" class="k-textbox" />
      <v-btn @click="updateText">Update</v-btn>
    </k-dialog>
  </div>
</template>
<script>
import Service from "@/services/Service.js";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { process } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import DetailComponentV2 from "./DetailComponentV2.vue";
import PreviewCell from "./PreviewCell.vue";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import {
  Dialog,
  DialogActionsBar,
  DialogActionButton,
  DialogActions,
} from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import DeleteCell from "./DeleteCell.vue";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    "k-dialog": Dialog,
    kbutton: Button,
    deletecell: DeleteCell,
    "dialog-actions-bar": DialogActionsBar,
    custom: DetailComponentV2,
    previewcell: PreviewCell,
    "kendo-dialog-actions": DialogActions,
    "kendo-dialog-action-button": DialogActionButton,
  },
  props: {
    tick: String,
  },
  computed: {
    result: {
      get: function () {
        return process(this.gridData, {
          sort: this.sort,
          filter: this.filter,
          take: this.take,
          skip: this.skip,
        });
      },
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
  },
  data: function () {
    return {
      columnProps: {
        'name': {
          className: 'name-column',
        },
      },
      dialogVisible2: false,
      selectedItem: {},
      editedText: "",
      dialogVisible: false,
      code: "",
      showDialog: false,
      visibleDialog: false,
      previewData: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Customer", value: "customer" },
        { text: "Account", value: "account" },
        { text: "Monitoring System", value: "monitoring_system" },
        { text: "Check Frequency", value: "check_frequency" },
      ],
      newGroupName: null,
      cellTemplate: "myTemplate",
      requiredRule: [(v) => !!v || "This field is required"],
      expandedField: "expanded",
      expandedItems: [],
      columns: [
        { field: "id", title: "ID", width: "100px" },
        {
          field: "name",
          title: "Name",
          width: "650px",
          cell: this.cellFunction,
        },
        {
          title: "Preview",
          cell: "previewTemplate",
          filterable: false,
          width: "150px",
        },
        {
          title: "Token",
          cell: "genTemplate",
          filterable: false,
          width: "150px",
        },
        {
          title: "Delete",
          cell: "delTemplate",
          filterable: false,
          width: "150px",
        },
      ],
      gridData: [],
      skip: 0,
      take: 5,
      sort: null,
      filter: null,
      pageable: { pageSizes: [5, 10, 25, 50, 100] },
      pageSize: 5,
    };
  },
  created: function () {
    this.getData();
  },
  methods: {
    copyCode() {
      this.$refs.codeTextarea.select();
      document.execCommand("copy");
      this.$refs.codeTextarea.blur();
    },
    createGroup() {
      var params = {
        name: this.newGroupName,
      };
      Service.createDynSiteGroupV2(params)
        .then((response) => {
          console.log("success");
          Vue.$toast.success(`Site Group saved`, {
            position: "top-right",
            duration: 4000,
          });
          this.getData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteSiteGroup(e) {
      console.log(`delete site group ${e.dataItem.id}`);
      Service.deleteRptSiteGroup(e.dataItem.id)
        .then((response) => {
          Vue.$toast.warning(`Site Group removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.getData();
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing group`, { position: "top-right" });
          console.log(error);
        });
    },
    expandChange: function (event) {
      // find by id and update
      const idx = this.gridData.findIndex((c) => {
        return c.id === event.dataItem.id;
      });
      this.gridData[idx].expanded = event.value;
    },
    generateData(item) {
      this.dialogVisible = true;
      // TODO: make call to generate fleet token url here...
      // http://localhost:4000/rpt/generate_fleet_view_url?site_group_id=33
      Service.genFleetViewUrl(item.id)
        .then((response) => {
          console.log(`generate data ${item}`);
          this.code = response.data.url;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading url`, { position: "top-right" });
          console.log(error);
        });
    },
    getData: function () {
      NProgress.start();
      // TODO: make sure and get only groups dynamic = false
      Service.getDynRptSiteGroups()
        .then((response) => {
          // this.gridData = response.data
          // add expanded property for detail view
          this.gridData = response.data.map((item) => {
            return { ...item, expanded: false };
          });
          NProgress.done();
        })
        .catch((error) => {
          NProgress.done();
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        });
    },
    previewRptList(e) {
      console.log(e.dataItem);
      // load data
      var params = {
        site_group_id: e.dataItem.id,
      };
      Service.dynSiteGroupQuery(params)
        .then((response) => {
          console.log("success");
          this.showDialog = true;
          this.previewData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      // show dialog
    },
    // getFilteredProducts(catId) {
    //   const result = filterBy(products, {
    //     field: "Category.id",
    //     operator: "eq",
    //     value: catId,
    //   });

    //   return result;
    // },
    cancel() {
      this.showDialog = false;
    },
    sortChangeHandler(event) {
      this.sort = event.sort;
    },
    toggleDialog() {
      this.visible = false;
    },
    filterChangeHandler(event) {
      this.filter = event.filter;
    },
    pageChangeHandler(event) {
      this.take = event.page.take;
      this.skip = event.page.skip;
    },
    cellFunction: function (h, tdElement, props, listeners) {
      const name = props.dataItem.name;
      const handleClick = () => {
        this.selectedItem = {
          "id": props.dataItem.id,
          "name": name
        };
        this.editedText = name;
        this.dialogVisible2 = true;
      };

      return h(
        "td",
        {
          on: {
            click: handleClick,
          },
        },
        [name]
      );
    },
    openDialog(event) {
      if (event.columnIndex !== 1) return; // Adjust the column index according to your column configuration
      const dataItem = event.dataItem;
      this.selectedText = dataItem.text;
      this.dialogVisible2 = true;
    },
    closeDialog() {
      this.dialogVisible2 = false;
      this.selectedText = "";
    },
    updateText() {
      // Perform update logic using this.editedText
      console.log('Updated text:', this.editedText);
      console.log(this.selectedItem);
      var params = {
        id: this.selectedItem.id,
        name: this.editedText
      };
      Service.updateDynSiteGroup(params)
        .then((response) => {
          console.log("success")
          Vue.$toast.success(`Site Group updated`, {
            position: "top-right",
            duration: 4000,
          });
          this.getData();
          // Reset the dialog
          this.dialogVisible2 = false;
          this.selectedItem = {};
          this.editedText = '';
        })
        .catch((error) => {
          console.log(error);
        });
      
    },
  },
  watch: {
    tick() {
      console.log("refresh ");
      this.getData();
    },
  },
};
</script>
<style scoped>
.name-column {
  cursor: pointer;
  text-decoration: underline;
}
</style>