var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("grid", {
    attrs: {
      "data-items": _vm.dataItem.rpt_field_groups,
      columns: _vm.columns,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }