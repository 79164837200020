var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Grid",
        {
          ref: "grid",
          style: { height: "550px" },
          attrs: {
            "data-items": _vm.result,
            sortable: true,
            sort: _vm.sort,
            filterable: true,
            filter: _vm.filter,
            pageable: _vm.pageable,
            take: _vm.take,
            skip: _vm.skip,
            "page-size": _vm.pageSize,
            total: _vm.total,
            columns: _vm.columns,
            detail: _vm.cellTemplate,
            "expand-field": _vm.expandedField,
          },
          on: {
            sortchange: _vm.sortChangeHandler,
            filterchange: _vm.filterChangeHandler,
            pagechange: _vm.pageChangeHandler,
            expandchange: _vm.expandChange,
          },
          scopedSlots: _vm._u([
            {
              key: "myTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("custom", { attrs: { "data-item": props.dataItem } }),
                ]
              },
            },
            {
              key: "delTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("deletecell", {
                    attrs: { "data-item": props.dataItem },
                    on: { remove: _vm.deleteRptList },
                  }),
                ]
              },
            },
            {
              key: "editTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("editcell", {
                    attrs: { "data-item": props.dataItem },
                    on: { edit: _vm.editRptList },
                  }),
                ]
              },
            },
            {
              key: "runTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("runcell", {
                    attrs: { "data-item": props.dataItem },
                    on: { click: _vm.runRptList },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c("grid-norecords", [_vm._v(" There is no data available custom ")]),
          _c("grid-toolbar", [
            _c(
              "div",
              { staticStyle: { padding: "10px" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "font-weight-bold text-xs btn-default bg-gradient-default",
                    attrs: {
                      ripple: false,
                      elevation: 0,
                      id: "create_rpt_btn",
                    },
                    on: { click: _vm.startCreateRpt },
                  },
                  [_vm._v(" Create Report ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.visible
        ? _c(
            "window",
            {
              attrs: {
                title: _vm.selectedRpt.name,
                "initial-top": 50,
                "initial-left": 50,
                "initial-height": 350,
                "initial-width": 650,
              },
              on: { close: _vm.toggleDialog },
            },
            [_c("rpt-window", { attrs: { rpt: _vm.selectedRpt } })],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "650" },
          model: {
            value: _vm.showCreateRptList,
            callback: function ($$v) {
              _vm.showCreateRptList = $$v
            },
            expression: "showCreateRptList",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "", height: "450" } },
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "grey darken-3" } },
                [
                  _c("v-toolbar-title", { staticStyle: { color: "white" } }, [
                    _vm._v(" Create Custom Report "),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeCreateRptList()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "10" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-4",
                        staticStyle: { "padding-top": "10px" },
                        attrs: { label: "Report Name" },
                        model: {
                          value: _vm.newRptName,
                          callback: function ($$v) {
                            _vm.newRptName = $$v
                          },
                          expression: "newRptName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _c(
                      "div",
                      [
                        _c("br"),
                        _c("v-select", {
                          attrs: {
                            items: _vm.fieldLists,
                            label: "Select Field List",
                            "item-text": "name",
                            "item-value": "id",
                            required: "",
                          },
                          model: {
                            value: _vm.fieldList,
                            callback: function ($$v) {
                              _vm.fieldList = $$v
                            },
                            expression: "fieldList",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _c(
                      "div",
                      [
                        _c("br"),
                        _c("v-select", {
                          attrs: {
                            items: _vm.siteGroups,
                            label: "Select Site Group",
                            "item-text": "name",
                            "item-value": "id",
                            required: "",
                          },
                          model: {
                            value: _vm.siteGroup,
                            callback: function ($$v) {
                              _vm.siteGroup = $$v
                            },
                            expression: "siteGroup",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("br"),
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { loading: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.saveRptList()
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }