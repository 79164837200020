<template>
  <div>
    <!-- <br />
    <v-btn
      :ripple="false"
      :elevation="0"
      class="font-weight-bold text-xs btn-default bg-gradient-default"
      id="upload_sites_btn"
      @click="startCreateFieldList"
    >
      Create Field List
    </v-btn>
    <v-data-table
    :headers="headers"
    :items="gridData"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    :items-per-page="10"
    item-key="name"
    show-expand
    class="elevation-1"
    >
    <template v-slot:item.controls="props">
              <v-btn
                class="mx-2"
                icon
                color="red"
                @click="deleteFieldList(props.item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td colspan="4" style="padding-left: 0px; padding-right: 0px;width: 100%;"><rpt-field-detail v-bind:dataItem="item"></rpt-field-detail></td>
      </template>
    </v-data-table> -->
    <Grid
      ref="grid"
      :data-items="result"
      :sortable="true"
      :sort="sort"
      :filterable="true"
      :filter="filter"
      :pageable="pageable"
      :skip="skip"
      :take="take"
      :page-size="pageSize"
      :total="total"
      @sortchange="sortChangeHandler"
      @filterchange="filterChangeHandler"
      @pagechange="pageChangeHandler"
      :columns="columns"
      :detail="cellTemplate"
      :loader="loader"
      @expandchange="expandChange"
      :expand-field="'expanded'"
    >
      <template v-slot:delTemplate="{ props }">
        <deletecell :data-item="props.dataItem" @remove="deleteFieldList" />
      </template>
      <template v-slot:editTemplate="{ props }">
        <editcell :data-item="props.dataItem" @edit="editFieldList" />
      </template>
      <template v-slot:myTemplate="{ props }">
        <custom :data-item="props.dataItem" />
      </template>
      <grid-toolbar>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="upload_sites_btn"
          @click="startCreateFieldList"
        >
          Create Field List
        </v-btn>
      </grid-toolbar>
    </Grid>
    <!-- Edit field list dialog -->
    <v-dialog v-model="showEditFieldList" width="650">
      <edit-rpt-field-list-dialog
        @update="updateFieldList"
        @close="closeEditFieldListDialog"
        :internalReports="internalReports"
        :rptFieldList="eRptFieldList"
      >
      </edit-rpt-field-list-dialog>
    </v-dialog>
    <!-- create field list dialog -->
    <v-dialog v-model="showCreateFieldList" width="650">
      <v-card >
        <v-toolbar flat :color="'grey darken-3'">
          <v-toolbar-title style="color: white">
            Create Custom Field List
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeCreateFieldList()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- <v-row align="center" justify="center">
          <v-col cols="10">
            <v-text-field label="List Name" v-model="newFieldListName">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <div>
              <br />
              <v-select
                :items="internalReports"
                @change="showFields()"
                label="Select Base Report"
                item-text="name"
                item-value="id"
                v-model="selectedInternalReport"
                required
              ></v-select>
            </div>
          </v-col>
        </v-row> -->
        <!-- <v-row align="center" justify="center">
          <v-col cols="10"> Select fields </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <v-list style="height: 300px; overflow-y: auto">
              <v-list-item-group
                v-model="selectedFields"
                multiple
                color="indigo"
              >
                <v-list-item
                  v-for="(item, i) in fieldItems"
                  :key="i"
                  :value="item.id"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ item.rpt_column_text }} -
                      {{ item.name }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row> -->
       <div class="container">
         <v-text-field label="List Name" v-model="newFieldListName"></v-text-field>
          <v-select
              :items="internalReports"
              @change="showFields()"
              label="Select Base Report"
              item-text="name"
              item-value="id"
              v-model="selectedInternalReport"
              required
            ></v-select>  
            <!-- <drag-and-drop @update=setSelectedFields :items="fieldItems"></drag-and-drop> -->
             <draggable
              :list="fieldItems"
              class="test"
            >
              <div class="inner"
                v-for="element in fieldItems"
                :key="element.name"
              ><v-checkbox :label="`${element.rpt_column_text}`" v-model="element.checked"></v-checkbox>
              </div>
            </draggable>
            <!-- <draggable class="list-group" draggable=".item" v-model="fieldItems">
                <transition-group>
                    <div v-for="element in fieldItems" :key="element.id">
                        {{element.name}}
                    </div>
                </transition-group>
            </draggable> -->
       </div>
        <v-card-actions>
          <br />
          <v-row align="center" justify="center">
            <v-btn @click="saveFieldList()"> Save </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";

import { Button } from "@progress/kendo-vue-buttons";
import { process } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import DeleteCell from "./DeleteCell.vue";
import EditRptFieldListDialog from "./EditRptFieldListDialog.vue";
import EditCell from "./EditCell.vue";
import { Dialog } from "@progress/kendo-vue-dialogs";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";
import RptFieldDetail from "./RptFieldDetail.vue";
import DragAndDrop from "./DragAndDrop.vue";
import draggable from 'vuedraggable';

Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    EditRptFieldListDialog,
    kbutton: Button,
    deletecell: DeleteCell,
    editcell: EditCell,
    RptFieldDetail,
    custom: RptFieldDetail,
    DragAndDrop,
    draggable,
  },
  props: {
    tick: String
  },
  data: function () {
    return {
      loader: false,
      expanded: [],
      pageable: { pageSizes: [10, 20, 50, 100] },
      eRptFieldList: {},
      singleExpand: false,
      cellTemplate: "myTemplate",
      expandedItems: [],
      pageSize: 20,      
      updatedData: [],
      internalReports: [],
      selectedFields: [],
      newFieldListName: null,
      fieldItems: [],
      selectedInternalReport: null,
      showCreateFieldList: false,
      showEditFieldList: false,
      editID: null,
      sort: null,
      filter: null,
      skip: 0,
      take: 10,
      headers: [
        // { value: 'id', visible: false, text: 'ID', width: '100px' },
        { value: "name", text: "Name", width: "150px" },
        {
          value: "rpt_internal_report_name",
          text: "Report Name",
          width: "175px",
        },
        { text: "Remove", value: "controls", sortable: false },
      ],
      columns: [
        { field: "id", editable: false, title: "ID", width: "80px" },
        { field: "name", title: "Name", width: "175px" },
        {
          field: "rpt_internal_report_name",
          title: "Report Name",
          width: "175px",
        },
        {
          title: "Edit",
          cell: "editTemplate",
          filterable: false,
          width: "150px",
        },
        {
          title: "Remove",
          cell: "delTemplate",
          filterable: false,
          width: "150px",
        },
      ],
      gridData: [],
    };
  },
  watch: {
    tick() {
      console.log('refresh ');
      // this.getData();
    }
  },
  created: function () {
    this.getList();
    this.getInternalReports();
  },
  computed: {
    result: {
      get: function () {
        return process(this.gridData, {
          sort: this.sort,
          filter: this.filter,
          take: this.take,
          skip: this.skip,
        });
      },
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
  },
  methods: {
    expandChange: function (event) {
      // find by id and update
      const idx = this.gridData.findIndex((c) => {
        return c.id === event.dataItem.id;
      });
      this.gridData[idx].expanded = event.value;
    },
    saveFieldList() {
      // get selected report
      let rpt = this.internalReports.find(
        (r) => r.id == this.selectedInternalReport
      );
      // get selected fields
      const fields = this.fieldItems.filter(f => f.checked == true);
      let ids = fields.map((item, index) => {
        console.log(item.id);
        item.order = index;
        return item;
      });
      var params = {
        name: this.newFieldListName,
        rpt_internal_id: rpt.id,
        rpt_field_ids: ids,
      };

      console.log("");
      // make the call to save list, then refresh data
      Service.createRptFieldList(params)
        .then((response) => {
          console.log("rptFieldList created");
          Vue.$toast.success(`Field list created`, { position: "top-right" });
          // close modal and refresh grid
          this.showCreateFieldList = false;
          this.getList();
        })
        .catch((error) => {
          Vue.$toast.error(`Error creating list`, { position: "top-right" });
          console.log(error);
        });
    },
    showFields() {
      console.log("");
      // grab fields from selected report
      let rpt = this.internalReports.find(
        (r) => r.id == this.selectedInternalReport
      );
      // get fields
      // this.fieldItems = rpt.rpt_fields;
      // set arbitrary order
      // TODO: replace this with DB column
      this.fieldItems = rpt.rpt_fields.map((item, index) => {
        return {...item, checked: false, order: index};
      });
      // this.fieldItems.sort(this.dynamicSort("order"))
    },
    dynamicSort(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
            * and you may want to customize it to your needs
            */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    },
    deleteFieldList(e) {
      console.log(`delete field list id = ${e.dataItem.id}`);
      Service.deleteRptFieldList(e.dataItem.id)
        .then((response) => {
          Vue.$toast.warning(`Field List removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.getList();
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing list`, { position: "top-right" });
          console.log(error);
        });
    },
    startCreateFieldList() {
      this.showCreateFieldList = true;
      // reset values
      this.selectedFields = [];
      this.fieldItems = [];
      this.selectedInternalReport = null;
    },
    editFieldList(e) {
      
      let item = Object.assign({}, e.dataItem);
      this.eRptFieldList = item;
      
      this.showEditFieldList = true;
    },
    updateFieldList(e) {
      this.showEditFieldList = false;
      Vue.$toast.success(`Field list updated`, { position: "top-right" });  
      this.getList();
    },
    closeEditFieldListDialog() {
      this.showEditFieldList = false;
    },
    closeCreateFieldList() {
      this.showCreateFieldList = false;
    },
    sortChangeHandler(event) {
      this.sort = event.sort;
    },
    filterChangeHandler(event) {
      this.filter = event.filter;
    },
    pageChangeHandler(event) {
      this.take = event.page.take;
      this.skip = event.page.skip;
    },
    getInternalReports() {
      Service.getRptInternalReports()
        .then((response) => {
          this.internalReports = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getList() {
      NProgress.start();
      Service.getRptFieldList()
        .then((response) => {
          // this.gridData = response.data;
          // add expanded property for detail view
          this.gridData = response.data.map((item) => {
            return { ...item, expanded: false };
          });
          NProgress.done();
        })
        .catch((error) => {
          NProgress.done();
          console.log(error);
        });
    },
    setSelectedFields(e) {
      console.log(`setting selected fields ${e.data}`);
      this.selectedFields = e.data;
    }
  },
};
</script>

<style scoped>
.test {
  /* background-color: black;
  padding: 1px; */
}

.inner {
  background-color: white;
  padding: 5px;
  border: 2px solid #edf4f6;
}
/* .k-loading-mask .k-loading-image {
  background-image: url('http://blog.teamtreehouse.com/wp-content/uploads/2015/05/loading.gif');
}  */

</style>