var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "example-wrapper" },
    [
      _c(
        "Grid",
        {
          ref: "grid",
          style: { height: "300px" },
          attrs: {
            "data-items": _vm.result,
            columns: _vm.columns,
            sortable: true,
            sort: _vm.sort,
          },
          on: { sortchange: _vm.sortChangeHandler },
          scopedSlots: _vm._u([
            {
              key: "myTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("custom", {
                    attrs: { "data-item": props.dataItem },
                    on: { edit: _vm.edit, remove: _vm.remove },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "grid-toolbar",
            [
              _vm._v("  "),
              _c(
                "v-btn",
                {
                  attrs: { title: "Add new", "theme-color": "primary" },
                  on: { click: _vm.insert },
                },
                [_vm._v(" Add new ")]
              ),
              _vm._v("  "),
              _c(
                "v-btn",
                {
                  attrs: { title: "Help", "theme-color": "primary" },
                  on: { click: _vm.openDialog },
                },
                [_vm._v(" Help ")]
              ),
            ],
            1
          ),
          _c("grid-norecords", [_vm._v(" There is no data available custom ")]),
        ],
        1
      ),
      _vm.itemInEdit
        ? _c("dialog-container", {
            staticStyle: { "z-index": "9999" },
            attrs: { "data-item": _vm.itemInEdit },
            on: { save: _vm.save, cancel: _vm.cancel },
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "750" },
          model: {
            value: _vm.helpdialog,
            callback: function ($$v) {
              _vm.helpdialog = $$v
            },
            expression: "helpdialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [_vm._v("Help")]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    'For field level filters, each group you create will take all filters and "OR" or union them together. '
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "For example, if you want sites that are daily or weekly, you will create a filter of group 1, that includes field name check_frequency = daily, then create another filter item of group 1, that includes field name check_frequency = weekly. In this first group you will get all the sites that are either daily or weekly."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    'For an "AND" operation or to create an intersection, you will now create another filter item of group 2, that includes field name customer LIKE %Rev%. This will now AND your first group (group 1) of daily or weekly with this second group of sites that are only of the customer Revision. '
                  ),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { "theme-color": "primary" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }