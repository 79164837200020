var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("tr", [
      _vm._m(0),
      _c("td", [_vm._v(_vm._s(_vm.dataItem.rpt_field_list.name))]),
      _c("td", [
        _vm._v(
          _vm._s(_vm.dataItem.rpt_field_list.rpt_field_groups.length) +
            " Fields"
        ),
      ]),
    ]),
    _c("tr", [
      _vm._m(1),
      _c("td", [_vm._v(_vm._s(_vm.dataItem.rpt_site_group.name))]),
      _c("td", [_vm._v(_vm._s(this.sites) + " Sites")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("b", [_vm._v("Report Field List")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("b", [_vm._v("Report Site Group")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }