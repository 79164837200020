var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Grid",
        {
          ref: "grid",
          attrs: {
            "data-items": _vm.result,
            sortable: true,
            sort: _vm.sort,
            filterable: true,
            filter: _vm.filter,
            pageable: _vm.pageable,
            skip: _vm.skip,
            take: _vm.take,
            "page-size": _vm.pageSize,
            total: _vm.total,
            columns: _vm.columns,
            detail: _vm.cellTemplate,
            loader: _vm.loader,
            "expand-field": "expanded",
          },
          on: {
            sortchange: _vm.sortChangeHandler,
            filterchange: _vm.filterChangeHandler,
            pagechange: _vm.pageChangeHandler,
            expandchange: _vm.expandChange,
          },
          scopedSlots: _vm._u([
            {
              key: "delTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("deletecell", {
                    attrs: { "data-item": props.dataItem },
                    on: { remove: _vm.deleteFieldList },
                  }),
                ]
              },
            },
            {
              key: "editTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("editcell", {
                    attrs: { "data-item": props.dataItem },
                    on: { edit: _vm.editFieldList },
                  }),
                ]
              },
            },
            {
              key: "myTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("custom", { attrs: { "data-item": props.dataItem } }),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "grid-toolbar",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: {
                    ripple: false,
                    elevation: 0,
                    id: "upload_sites_btn",
                  },
                  on: { click: _vm.startCreateFieldList },
                },
                [_vm._v(" Create Field List ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "650" },
          model: {
            value: _vm.showEditFieldList,
            callback: function ($$v) {
              _vm.showEditFieldList = $$v
            },
            expression: "showEditFieldList",
          },
        },
        [
          _c("edit-rpt-field-list-dialog", {
            attrs: {
              internalReports: _vm.internalReports,
              rptFieldList: _vm.eRptFieldList,
            },
            on: {
              update: _vm.updateFieldList,
              close: _vm.closeEditFieldListDialog,
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "650" },
          model: {
            value: _vm.showCreateFieldList,
            callback: function ($$v) {
              _vm.showCreateFieldList = $$v
            },
            expression: "showCreateFieldList",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "grey darken-3" } },
                [
                  _c("v-toolbar-title", { staticStyle: { color: "white" } }, [
                    _vm._v(" Create Custom Field List "),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeCreateFieldList()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c("v-text-field", {
                    attrs: { label: "List Name" },
                    model: {
                      value: _vm.newFieldListName,
                      callback: function ($$v) {
                        _vm.newFieldListName = $$v
                      },
                      expression: "newFieldListName",
                    },
                  }),
                  _c("v-select", {
                    attrs: {
                      items: _vm.internalReports,
                      label: "Select Base Report",
                      "item-text": "name",
                      "item-value": "id",
                      required: "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.showFields()
                      },
                    },
                    model: {
                      value: _vm.selectedInternalReport,
                      callback: function ($$v) {
                        _vm.selectedInternalReport = $$v
                      },
                      expression: "selectedInternalReport",
                    },
                  }),
                  _c(
                    "draggable",
                    { staticClass: "test", attrs: { list: _vm.fieldItems } },
                    _vm._l(_vm.fieldItems, function (element) {
                      return _c(
                        "div",
                        { key: element.name, staticClass: "inner" },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "" + element.rpt_column_text },
                            model: {
                              value: element.checked,
                              callback: function ($$v) {
                                _vm.$set(element, "checked", $$v)
                              },
                              expression: "element.checked",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("br"),
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.saveFieldList()
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }