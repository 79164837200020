var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { tile: "", height: "680" } },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "grey darken-3" } },
            [
              _c("v-toolbar-title", { staticStyle: { color: "white" } }, [
                _vm._v(" Edit Custom Field List "),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close", {})
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container", staticStyle: { background: "white" } },
            [
              _c("v-text-field", {
                attrs: { label: "List Name" },
                model: {
                  value: _vm.newFieldListName,
                  callback: function ($$v) {
                    _vm.newFieldListName = $$v
                  },
                  expression: "newFieldListName",
                },
              }),
              _c("v-select", {
                attrs: {
                  items: _vm.internalReports,
                  label: "Select Base Report",
                  "item-text": "name",
                  "item-value": "id",
                  required: "",
                },
                on: {
                  change: function ($event) {
                    return _vm.showFields()
                  },
                },
                model: {
                  value: _vm.selectedInternalReport,
                  callback: function ($$v) {
                    _vm.selectedInternalReport = $$v
                  },
                  expression: "selectedInternalReport",
                },
              }),
              _c(
                "draggable",
                { staticClass: "test", attrs: { list: _vm.selectedFields } },
                _vm._l(_vm.selectedFields, function (element) {
                  return _c(
                    "div",
                    { key: element.name, staticClass: "inner" },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "" + element.rpt_column_text },
                        model: {
                          value: element.checked,
                          callback: function ($$v) {
                            _vm.$set(element, "checked", $$v)
                          },
                          expression: "element.checked",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("br"),
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.saveFieldList()
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }