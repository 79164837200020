<template>
  <div>
    <!-- <button @click="toggleCollapse">{{ collapsed ? 'Show' : 'Hide' }}</button> -->
    <div v-if="!collapsed">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    toggleCollapse() {
      this.$emit('toggle-collapse');
    },
  },
};
</script>