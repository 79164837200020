var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "padding-left": "5px",
            "padding-top": "5px",
            "padding-bottom": "5px",
          },
        },
        [
          _c("v-btn", { on: { click: _vm.toggleCollapse } }, [
            _vm._v(
              _vm._s(_vm.collapsed ? "Show Create Dialog" : "Hide Dialog")
            ),
          ]),
        ],
        1
      ),
      _c(
        "my-collapsible",
        { attrs: { collapsed: _vm.collapsed } },
        [
          _c(
            "v-card",
            {
              staticStyle: {
                border: "1px solid black",
                "box-shadow": "0 2px 1px rgba(0,0,0,0.2)",
              },
            },
            [
              _c("v-card-title", [_vm._v("Dynamic SQL Query Tool")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Group Name" },
                                    model: {
                                      value: _vm.newGroupName,
                                      callback: function ($$v) {
                                        _vm.newGroupName = $$v
                                      },
                                      expression: "newGroupName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.fieldNames,
                                      label: "Field name",
                                      rules: [_vm.requiredRule],
                                    },
                                    model: {
                                      value: _vm.columnName,
                                      callback: function ($$v) {
                                        _vm.columnName = $$v
                                      },
                                      expression: "columnName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.operators,
                                      label: "Operator",
                                    },
                                    model: {
                                      value: _vm.operator,
                                      callback: function ($$v) {
                                        _vm.operator = $$v
                                      },
                                      expression: "operator",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      label: "Value",
                                                      rules: [_vm.requiredRule],
                                                    },
                                                    model: {
                                                      value: _vm.value,
                                                      callback: function ($$v) {
                                                        _vm.value = $$v
                                                      },
                                                      expression: "value",
                                                    },
                                                  },
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [_c("span", [_vm._v("Tooltip text")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "font-weight-bold text-xs btn-default bg-gradient-default",
                                      on: { click: _vm.addCondition },
                                    },
                                    [_vm._v("Add Condition")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-subheader",
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-list-item-subtitle", [
                                    _vm._v("Column"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-list-item-subtitle", [
                                    _vm._v("Operator"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [_c("v-list-item-subtitle", [_vm._v("Value")])],
                                1
                              ),
                              _c("v-col", { attrs: { cols: "1" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(_vm.conditions, function (condition, index) {
                        return _c(
                          "v-list-item",
                          { key: index },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "condition-row",
                                attrs: { align: "center" },
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(condition.column)),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(condition.operator)),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(condition.value)),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "1" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "", color: "error" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeCondition(index)
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-delete")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _c("v-divider"),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-xs btn-default bg-gradient-default",
                                  on: { click: _vm.cancel },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _vm._v("  "),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-xs btn-default bg-gradient-default",
                                  on: { click: _vm.preview },
                                },
                                [_vm._v("Preview")]
                              ),
                              _vm._v("  "),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-xs btn-default bg-gradient-default",
                                  on: { click: _vm.create },
                                },
                                [_vm._v("Save")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "my-data-table-container" },
                              [
                                _vm.previewData.length > 0
                                  ? _c("v-data-table", {
                                      attrs: {
                                        headers: _vm.headers,
                                        items: _vm.previewData,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "Grid",
                    {
                      ref: "gridg",
                      attrs: { "data-items": _vm.result, columns: _vm.columns },
                      scopedSlots: _vm._u([
                        {
                          key: "delTemplate",
                          fn: function (ref) {
                            var props = ref.props
                            return [
                              _c("deletecell", {
                                attrs: { "data-item": props.dataItem },
                                on: { remove: _vm.deleteSiteGroup },
                              }),
                            ]
                          },
                        },
                        {
                          key: "editTemplate",
                          fn: function (ref) {
                            var props = ref.props
                            return [
                              _c("editcell", {
                                attrs: { "data-item": props.dataItem },
                                on: { edit: _vm.editSiteGroup },
                              }),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("grid-norecords", [
                        _vm._v(" There is no data available custom "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }