<template>
<grid 
   :data-items="dataItem.rpt_field_groups"
    :columns="columns"
    >
</grid>
    <!-- <div>
        test
    </div> -->
</template>
<script>
import { Grid } from '@progress/kendo-vue-grid';
import { filterBy } from '@progress/kendo-data-query';

export default {
    components: {
        grid: Grid
    },
    props: {
        dataItem: Object
    },
    created() {
        console.log('created');
        this.columns[0].sortDir = "asc"; // Set initial sorting direction
    this.sortGridData();
    },
    data () {
        return {
            columns: [
                // { field: 'id', filterable: false, title: 'ID', width: '150px' },
                { field: 'order', title: 'Order', width: '100px', sortable: true},
                { field: 'rpt_column_text', title: 'Field Name', width: '200px'},
            ]
        }
    },
    methods: {
        sortGridData() {
            const sortedData = this.dataItem.rpt_field_groups.slice().sort((a, b) => a.order - b.order);
            this.dataItem.rpt_field_groups = sortedData;
        }
    }
}
</script>