<template>
  <div>
    <v-card tile height="680">
      <v-toolbar flat :color="'grey darken-3'">
        <v-toolbar-title style="color: white">
          Edit Custom Field List
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('close', {})">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <v-row align="center" justify="center">
        <v-col cols="10">
          <v-text-field label="List Name" v-model="newFieldListName">
          </v-text-field>
        </v-col>
      </v-row> -->
      <!-- <v-row align="center" justify="center">
        <v-col cols="10">
          <div>
            <br />
            <v-select
              :items="internalReports"
              @change="showFields()"
              label="Select Base Report"
              item-text="name"
              item-value="id"
              v-model="selectedInternalReport"
              required
            ></v-select>
          </div>
        </v-col>
      </v-row> -->
      <!-- <v-row align="center" justify="center">
        <v-col cols="10"> Select fields </v-col>
      </v-row> -->
      <!-- <v-row align="center" justify="center">
        <v-col cols="10">
          <v-list style="height: 300px; overflow-y: auto">
            <v-list-item-group v-model="selectedFields" multiple color="indigo">
              <v-list-item
                v-for="(item, i) in field_list"
                :key="i"
                :value="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row> -->
      <div class="container" style="background: white">
         <v-text-field label="List Name" v-model="newFieldListName"></v-text-field>
          <v-select
              :items="internalReports"
              @change="showFields()"
              label="Select Base Report"
              item-text="name"
              item-value="id"
              v-model="selectedInternalReport"
              required
            ></v-select>  
            <!-- <drag-and-drop @update=setSelectedFields :items="selectedFields"></drag-and-drop> -->
             <draggable
              :list="selectedFields"
              class="test"
            >
              <div class="inner"
                v-for="element in selectedFields"
                :key="element.name"
              ><v-checkbox :label="`${element.rpt_column_text}`" v-model="element.checked"></v-checkbox>
              </div>
            </draggable>
       </div>
      <v-card-actions>
        <br />
        <v-row align="center" justify="center">
          <v-btn @click="saveFieldList()"> Save </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Service from "@/services/Service.js";
import DragAndDrop from "./DragAndDrop.vue";
import draggable from 'vuedraggable';

export default {
  components: {
    DragAndDrop,
    draggable,
  },
  props: {
    rptFieldList: Object,
    internalReports: Array,
  },
  data: function () {
    return {
      selectedFields: [],
      selectedInternalReport: null,
      field_list: []
    };
  },
  methods: {
    getData() {
      // load internal report list and select 
      this.selectedInternalReport = {
        name: this.rptFieldList.rpt_internal_report_name, 
        id: this.rptFieldList.rpt_internal_report_id
      };
      // find internal report
      let rpt = this.internalReports.find(r => r.id == this.rptFieldList.rpt_internal_report_id);
      // set field list for this internal report
      this.field_list = rpt.rpt_fields;
      // set selected fields, these should be checked=true
      this.selectedFields = this.rptFieldList.rpt_field_groups.map(g => {
        return {...g, id: g.rpt_field_id, checked: true, name: g.rpt_field, rpt_column_text: g.rpt_column_text};
      })
      // find max order
      const max = this.selectedFields.reduce(function(prev, current) {
          return (prev.order > current.order) ? prev : current
      }) //returns object
      // find additional fields that are not already added
      let diff = this.field_list.filter(x => !this.selectedFields.find(s => s.rpt_field_id == x.id));
      let newDiff = diff.map((d, index) => {
          return {...d, checked: false, order: (index+1+max.order)}
      })
      this.selectedFields = this.selectedFields.concat(newDiff);
      this.selectedFields.sort(this.dynamicSort("order"));
      this.selectedFields = this.selectedFields.map(s => {
        return {id: s.id, checked: s.checked, order: s.order, name: s.name, rpt_column_text: s.rpt_column_text};
      })
      this.newFieldListName = this.rptFieldList.name;
    },
    dynamicSort(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
            * and you may want to customize it to your needs
            */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    },
    setSelectedFields(e) {
      console.log(`setting selected fields ${e.data}`);
      this.selectedFields = e.data;
    },
    saveFieldList() {
      let rpt = this.internalReports.find(
        (r) => r.id == this.selectedInternalReport.id
      );
      // get selected fields
      const fields = this.selectedFields.filter(f => f.checked == true);
      // fix order  
      let ids = fields.map((item, index) => {
        console.log(item.id);
        item.order = index;
        return item;
      });
      
      var params = {
        id: this.rptFieldList.id,
        name: this.newFieldListName,
        rpt_internal_id: rpt.id,
        rpt_field_ids: ids,
      };

      console.log("");
      // make the call to save list, then refresh data
      Service.updateRptFieldList(params)
        .then((response) => {
          console.log("rptFieldList update");
          // Vue.$toast.success(`Field list updated`, { position: "top-right" });
          // close modal and refresh grid
          // TODO: emit refresh event and close modal
          this.$emit('update', {});
        })
        .catch((error) => {
          // Vue.$toast.error(`Error updating list`, { position: "top-right" });
          console.log(error);
        });
    }
  },
  watch: {
    rptFieldList() {
      console.log('rptFieldList changed');
      this.getData();
    }
  },
  created: function () {
    this.getData();
    console.log(this.rptFieldList);
    console.log(this.internalReports);
  },
};
</script>

<style scoped>
.inner {
  background-color: white;
  padding: 5px;
  border: 2px solid #edf4f6;
}
</style>