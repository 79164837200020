<template>
  <div>
    
    <Grid
      ref="grid"
      :style="{ height: '550px' }"
      :data-items="result"
      :sortable="true"
      :sort="sort"
      :filterable="true"
      :filter="filter"
      :pageable="pageable"
      :take="take"
      :skip="skip"
      :page-size="pageSize"
      :total="total"
      @sortchange="sortChangeHandler"
      @filterchange="filterChangeHandler"
      @pagechange="pageChangeHandler"
      :columns="columns"
      :detail="cellTemplate"
      @expandchange="expandChange"
      :expand-field="expandedField"
    >
      <template v-slot:myTemplate="{ props }">
        <custom :data-item="props.dataItem" />
      </template>
      <template v-slot:delTemplate="{ props }">
        <deletecell :data-item="props.dataItem" @remove="deleteRptList" />
      </template>
      <template v-slot:editTemplate="{ props }">
        <editcell :data-item="props.dataItem" @edit="editRptList" />
      </template>
      <template v-slot:runTemplate="{ props }">
        <runcell :data-item="props.dataItem" @click="runRptList" />
      </template>
      <grid-norecords> There is no data available custom </grid-norecords>
      <grid-toolbar>
        <div style="padding: 10px">
          <v-btn
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-default bg-gradient-default"
            id="create_rpt_btn"
            @click="startCreateRpt"
          >
            Create Report
          </v-btn>
          <!-- <h4 v-if="loading">
            <v-progress-circular indeterminate></v-progress-circular>
          </h4> -->
        </div>
      </grid-toolbar>
    </Grid>

    <window
      v-if="visible"
      :title="selectedRpt.name"
      @close="toggleDialog"
      :initial-top="50"
      :initial-left="50"
      :initial-height="350"
      :initial-width="650"
    >
      <rpt-window :rpt="selectedRpt"></rpt-window>
    </window>
    <!-- dialog to create report -->
    <v-dialog v-model="showCreateRptList" width="650">
      <v-card tile height="450">
        <v-toolbar flat :color="'grey darken-3'">
          <v-toolbar-title style="color: white">
            Create Custom Report
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeCreateRptList()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <v-text-field
              style="padding-top: 10px"
              class="mt-4"
              label="Report Name"
              v-model="newRptName"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <div>
              <br />
              <v-select
                :items="fieldLists"
                label="Select Field List"
                item-text="name"
                item-value="id"
                v-model="fieldList"
                required
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <div>
              <br />
              <v-select
                :items="siteGroups"
                label="Select Site Group"
                item-text="name"
                item-value="id"
                v-model="siteGroup"
                required
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <!-- <v-row align="center" justify="center">
          <v-col cols="10"> Select Field List </v-col>
        </v-row> -->
        <!-- <v-row align="center" justify="center">
        <v-col cols="10">
            <v-list style="height:300px; overflow-y:auto">
              <v-list-item-group
                v-model="fieldList"
                multiple
                color="indigo"
              >
                <v-list-item
                  v-for="(item, i) in fieldLists"
                  :key="i" :value="item.id"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
        </v-col>
      </v-row> -->
        <v-card-actions>
          <br />
          <v-row align="center" justify="center">
            <v-btn :loading=loading @click="saveRptList()"> Save </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { process } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import RptDetail from "./RptDetail";
import DeleteCell from "./DeleteCell.vue";
import EditCell from "./EditCell.vue";
import RptWindow from "./RptWindow.vue";
import { Window } from "@progress/kendo-vue-dialogs";
import Service from "@/services/Service.js";
import RunCell from "./RunCell.vue";

import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    window: Window,
    custom: RptDetail,
    deletecell: DeleteCell,
    editcell: EditCell,
    runcell: RunCell,
    "rpt-window": RptWindow,
  },
  props: {
    tick: String,
  },
  computed: {
    result: {
      get: function () {
        return process(this.gridData, {
          sort: this.sort,
          filter: this.filter,
          take: this.take,
          skip: this.skip,
        });
      },
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
    columns: function () {
      return [
        { field: "id", editable: false, title: "ID", width: "150px" },
        { field: "name", width: "275px" },
        {
          title: "Run",
          cell: "runTemplate",
          filterable: false,
          width: "250px",
        },
        {
          title: "Edit",
          cell: "editTemplate",
          filterable: false,
          width: "250px",
        },
        {
          title: "Remove",
          cell: "delTemplate",
          filterable: false,
          width: "250px",
        },
      ];
    },
  },
  data: function () {
    return {
      selectedRpt: null,
      createFlag: null,
      rpt_id_edit: null,
      loading: false,
      filter: null,
      fieldList: null,
      siteGroup: null,
      newRptName: null,
      visible: false,
      sort: null,
      showCreateRptList: false,
      pageable: { pageSizes: [5, 10, 25, 50, 100] },
      pageSize: 10,
      gridData: [],
      siteGroups: [],
      fieldLists: [],
      skip: 0,
      take: 10,
      expandedField: "expanded",
      cellTemplate: "myTemplate",
      expandedItems: [],
    };
  },
  watch: {
    tick() {
      console.log("refresh rptlist");
      this.getData();
    },
  },
  created: function () {
    this.getData();
  },

  methods: {
    closeCreateRptList() {
      this.showCreateRptList = false;
    },
    startCreateRpt() {
      this.fieldList = null;
      this.siteGroup = null;
      this.newRptName = null;
      this.createFlag = true;
      this.showCreateRptList = true;
    },
    editRptList(e) {
      let p1 = e.dataItem.rpt_field_list.id;
      let p2 = e.dataItem.rpt_site_group.id;
      let name = e.dataItem.name;
      this.fieldList = p1;
      this.siteGroup = p2;
      this.rpt_id_edit = e.dataItem.id;
      this.newRptName = name;
      this.createFlag = false;
      this.showCreateRptList = true;
    },
    deleteRptList(e) {
      console.log(`delete report ${e.dataItem.id}`);
      Service.deleteReport({id: e.dataItem.id})
        .then((response) => {
          Vue.$toast.warning(`Report removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.getData();
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing report`, { position: "top-right" });
          console.log(error);
        });
    },
    expandChange: function (event) {
      // find by id and update
      const idx = this.gridData.findIndex((c) => {
        return c.id === event.dataItem.id;
      });
      this.gridData[idx].expanded = event.value;
    },
    getData() {
      NProgress.start();
      // this.loading = true;
      Service.getReportList()
        .then((response) => {
          // this.gridData = response.data;
          // add expanded property for detail view
          this.gridData = response.data.map((item) => {
            return { ...item, expanded: false };
          });
          return Service.getAllRptSiteGroups();
        })
        .then((response) => {
          this.siteGroups = response.data;
          return Service.getRptFieldList();
        })
        .then((response) => {
          this.fieldLists = response.data;
          // this.loading = false;
          NProgress.done();
        })
        .catch((error) => {
          Vue.$toast.error(`Error report lists`, { position: "top-right" });
          console.log(error);
          NProgress.done();
          // this.loading = false;
        });
    },
    getFilteredProducts(catId) {
      const result = filterBy(products, {
        field: "Category.CategoryID",
        operator: "eq",
        value: catId,
      });

      return result;
    },
    runRptList(e) {
      console.log("run report...");
      this.selectedRpt = Object.assign({}, e.dataItem);
      this.visible = true;
    },
    saveRptList() {
      console.log("saveRptList");
      // // get selected report
      // let rpt = this.internalReports.find(r => r.id == this.selectedInternalReport);
      // // get selected fields
      // const fields = this.selectedFields;
      // let ids = fields.map(item => {
      //   console.log(item.id);
      //   return item;
      // });
      var params = {
        name: this.newRptName,
        rpt_field_list_id: this.fieldList,
        rpt_site_group_id: this.siteGroup,
      };

      // make the call to save list, then refresh data
      if(this.createFlag) {
        this.loading = true;
        Service.createReport(params)
          .then((response) => {
            console.log("report created");
            Vue.$toast.success(`Report created`, { position: "top-right" });
            // close modal and refresh grid
            this.showCreateRptList = false;
            this.getData();
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            Vue.$toast.error(`Error creating report`, { position: "top-right" });
            console.log(error);
          });
      } else {
        // Service.updateReport(params)
        var params = {
          id: this.rpt_id_edit,
          name: this.newRptName,
          rpt_field_list_id: this.fieldList,
          rpt_site_group_id: this.siteGroup,
        };
        this.loading = true;
        Service.updateReport(params)
          .then((response) => {
            console.log("report updated");
            Vue.$toast.success(`Report updated`, { position: "top-right" });
            // close modal and refresh grid
            this.loading = false;
            this.showCreateRptList = false;
            this.getData();
          })
          .catch((error) => {
            this.loading = false;
            Vue.$toast.error(`Error creating report`, { position: "top-right" });
            console.log(error);
          });
      }
    },
    sortChangeHandler(event) {
      this.sort = event.sort;
    },
    toggleDialog() {
      this.visible = false;
    },
    filterChangeHandler(event) {
      this.filter = event.filter;
    },
    pageChangeHandler(event) {
      this.take = event.page.take;
      this.skip = event.page.skip;
    },
  },
};
</script>