var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { align: "start" } },
        [
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "div",
              { staticClass: "border-radius-md mt-2 ml-4 mr-4" },
              [
                _c("v-text-field", {
                  attrs: { label: "Group Name" },
                  model: {
                    value: _vm.newGroupName,
                    callback: function ($$v) {
                      _vm.newGroupName = $$v
                    },
                    expression: "newGroupName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "left" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "font-weight-bold text-xs btn-success bg-gradient-success",
                    attrs: { ripple: false, elevation: 0 },
                    on: { click: _vm.createGroup },
                  },
                  [_vm._v("Create Group")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("Grid", {
                ref: "grid",
                style: { height: "600px" },
                attrs: {
                  "data-items": _vm.result,
                  sortable: true,
                  sort: _vm.sort,
                  filterable: true,
                  filter: _vm.filter,
                  pageable: _vm.pageable,
                  take: _vm.take,
                  skip: _vm.skip,
                  "page-size": _vm.pageSize,
                  total: _vm.total,
                  "column-props": _vm.columnProps,
                  detail: _vm.cellTemplate,
                  columns: _vm.columns,
                  "expand-field": _vm.expandedField,
                },
                on: {
                  sortchange: _vm.sortChangeHandler,
                  filterchange: _vm.filterChangeHandler,
                  pagechange: _vm.pageChangeHandler,
                  expandchange: _vm.expandChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "myTemplate",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c("custom", {
                          attrs: { "data-item": props.dataItem },
                        }),
                      ]
                    },
                  },
                  {
                    key: "previewTemplate",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c("previewcell", {
                          attrs: { "data-item": props.dataItem },
                          on: { click: _vm.previewRptList },
                        }),
                      ]
                    },
                  },
                  {
                    key: "genTemplate",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mx-2",
                                attrs: { icon: "", color: "green" },
                                on: {
                                  click: function ($event) {
                                    return _vm.generateData(props.dataItem)
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-refresh")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "delTemplate",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c("deletecell", {
                          attrs: { "data-item": props.dataItem },
                          on: { remove: _vm.deleteSiteGroup },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showDialog
        ? _c(
            "k-dialog",
            {
              attrs: { width: "950px", overlay: "false" },
              on: {
                close: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "div",
                          { staticClass: "my-data-table-container" },
                          [
                            _vm.previewData.length > 0
                              ? _c("v-data-table", {
                                  attrs: {
                                    headers: _vm.headers,
                                    items: _vm.previewData,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "dialog-actions-bar",
                [
                  _c("kbutton", { on: { click: _vm.cancel } }, [
                    _vm._v(" Close "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.dialogVisible
        ? _c(
            "k-dialog",
            {
              attrs: { width: "850px", title: "Copy the code below" },
              on: {
                close: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [
              _c("textarea", {
                ref: "codeTextarea",
                staticClass: "k-textarea",
                staticStyle: { width: "100%", "box-sizing": "border-box" },
                attrs: { rows: "5", readonly: "" },
                domProps: { value: _vm.code },
              }),
            ]
          )
        : _vm._e(),
      _vm.dialogVisible2
        ? _c(
            "k-dialog",
            {
              attrs: { width: 350, height: 150, title: "Edit Group Name" },
              on: {
                close: function ($event) {
                  _vm.dialogVisible2 = false
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editedText,
                    expression: "editedText",
                  },
                ],
                staticClass: "k-textbox",
                attrs: { type: "text" },
                domProps: { value: _vm.editedText },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.editedText = $event.target.value
                  },
                },
              }),
              _c("v-btn", { on: { click: _vm.updateText } }, [
                _vm._v("Update"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }