<template>
    <!-- <grid 
    :data-items="dataItem.report_list"
                        :columns="columns"
                        >
    </grid> -->
    <div>
        <tr>
            <td><b>Report Field List</b></td><td>{{dataItem.rpt_field_list.name}}</td><td>{{dataItem.rpt_field_list.rpt_field_groups.length}} Fields</td>
        </tr>
        <tr>
            <td><b>Report Site Group</b></td><td>{{dataItem.rpt_site_group.name}}</td><td>{{this.sites}} Sites</td>
        </tr>
    </div>
</template>
<script>
import { Grid } from '@progress/kendo-vue-grid';
import { filterBy } from '@progress/kendo-data-query';
import Service from "@/services/Service.js";

export default {
    components: {
        grid: Grid
    },
    props: {
        dataItem: Object
    },
    created() {
        // console.log('created');
        if(this.dataItem.rpt_site_group.dynamic) {
            // get dyn site count
            this.getDynamicSiteTotal();
        } else {
            this.site_count =  this.dataItem.rpt_site_group.rpt_sites.length;
        }
    },
    computed: {
        sites: function () {
            return this.site_count;
        },
    },
    data () {
        return {
            site_count: 0,
            columns: [
                { field: 'rpt_field_list.name', filterable: false, title: 'Field List', width: '250px' },
                { field: 'rpt_site_group.name', title: 'Site Group', width: '250px'},
            ]
        }
    },
    methods: {
        getDynamicSiteTotal() {
            Service.getDynSiteCount(this.dataItem.rpt_site_group.id)
            .then((response) => {
                this.site_count = response.data.site_count;
            })
            .catch((error) => {
                console.log(error);
            });
        },
    }
}
</script>