<template>
<div>
    <br />
    <div>
      <h1>Report Management</h1>
    </div>
    <v-tabs>
      <v-tab @click="loadReports()">Reports</v-tab>
      <v-tab @click="loadFieldLists()">Field Lists</v-tab>
      <v-tab @click="loadSiteGroups()">Site Groups</v-tab>
      <!-- <v-tab @click="loadDynamicSiteGroups()">Dynamic Site Groups</v-tab> -->
      <v-tab-item>
        <!-- reports -->
        <rpt-list :tick="rptTick"></rpt-list>
      </v-tab-item>
      <v-tab-item>
        <!-- field lists -->
        <rpt-field-lists></rpt-field-lists>
      </v-tab-item>
      <v-tab-item>
        <!-- site groups -->
        <create-group :tick="sgTick"></create-group>
      </v-tab-item>
      <!-- <v-tab-item> -->
        <!-- dynamic site groups -->
        <!-- <dynamic-site-groupv-2 :tick="dSgTick"></dynamic-site-groupv-2> -->
        <!-- <dynamic-site-group :tick="dSgTick"></dynamic-site-group> -->
      <!-- </v-tab-item> -->
    </v-tabs>
  </div>
</template>

<script>
import { v1 as uuidv1 } from 'uuid';
import CreateGroup from './CreateGroup.vue'
import RptFieldLists from './RptFieldLists.vue'
import RptList from './RptList.vue'
import DynamicSiteGroup from './DynamicSiteGroup.vue';
import DynamicSiteGroupv2 from './DynamicSiteGroupv2.vue';
export default {
  components: { RptFieldLists, CreateGroup, RptList,  DynamicSiteGroup, DynamicSiteGroupv2 },
  data() {
    return {
      rptTick: 'Help me',
      flTick: 'start',
      sgTick: 'start',
      dSgTick: 'start'
    }
  },
  mounted() {
    document.title = "Reports"
  },
  methods: {
    loadReports() {
      console.log('Reports click');
      this.rptTick = uuidv1();
      // this.tick = "1234";
    },
    loadFieldLists() {
      console.log('FieldLists click');
      this.flTick = uuidv1();
      // this.tick = "1234";
    },
    loadSiteGroups() {
      console.log('SiteGroups click');
      this.sgTick = uuidv1();
      // this.tick = "1234";
    },
    loadDynamicSiteGroups() {
      console.log('DyanmicSiteGroups click');
      this.dSgTick = uuidv1();
    }
  }
}
</script>

<style>

</style>