var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(_vm.current, {
        tag: "component",
        attrs: { loading: _vm.loading },
        on: { run: _vm.run },
      }),
      _c(
        "v-row",
        { staticClass: "mx-2 my-10" },
        [
          _c("Grid", {
            ref: "grid",
            attrs: { "data-items": _vm.items, columns: _vm.columns },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }